<template>
    <el-dialog
        title="Offer"
        :visible.sync="dialogVisible"
        width="500px"
        v-loading="loading"
        :before-close="cancelHandle"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        custom-class="offer-dialog">
        <el-form
            :model="offerForm"
            :rules="offerFormRules"
            ref="ruleForm"
            label-width="87px"
            class="offer-dialog-form"
            :disabled="disableDialog"
            v-loading="formLoading">
            <div class="offer-item-title" v-if="isPerformanceSplit && performanceSplitList.length > 0 && ![3, 5].includes(jobType)">
                候选人offer情况
            </div>

            <el-form-item
                label="签订月薪"
                :required="[3, 5].includes(jobType) ? false : true">
                <el-col
                    :span="12"
                    class="offer-monthpay-item">
                    <el-form-item prop="monthPay">
                        <el-input
                            v-model="offerForm.monthPay"
                            placeholder="请输入签订的月薪"
                            :disabled="(isEdit && disEditOffer)"
                            @blur="calculateServiceCharge">
                            <template slot="append">K</template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col
                    :span="12"
                    class="offer-monthpay-item">
                    <el-form-item prop="months">
                        <el-input
                            v-model="offerForm.months"
                            placeholder="请输入年薪月份"
                            maxlength="2"
                            :disabled="(isEdit && disEditOffer)"
                            @blur="calculateServiceCharge">
                            <template slot="append">个月</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-form-item>

            <!-- v-if="!disEditOffer" || [3, 5].includes(jobType) -->
            <el-form-item
                label="佣金比例"
                prop="commissionRate"
                >
                <el-input
                    v-model="offerForm.commissionRate"
                    placeholder="请输入佣金比例"
                    :disabled="(isEdit && disEditOffer)"
                    @blur="calculateServiceCharge">
                    <template slot="append">%</template>
                </el-input>
            </el-form-item>
            <el-form-item label="服务费" prop="serviceCharge" v-if="!isPerformanceSplit">
                <el-input
                    v-model="offerForm.serviceCharge"
                    placeholder="请输入服务费"
                ></el-input>
            </el-form-item>
            
            <el-form-item label="工作地点" prop="selectedCity">
                <el-cascader 
                    :props="{expandTrigger: 'hover', emitPath: false}" 
                    :options="cities" 
                    :show-all-levels="false" 
                    :clearable="true" 
                    v-model="offerForm.selectedCity" 
                    @change="selectCity" 
                    filterable
                    >
                </el-cascader>
            </el-form-item>
            
            <el-form-item label="入职时间" prop="recruiteTime">
                <el-date-picker
                    v-model="offerForm.recruiteTime"
                    type="date"
                    :disabled="isEdit && disEditOffer"
                    value-format="yyyy-MM-dd"
                    :picker-options="datePickerOption"
                    placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            
            <el-form-item label="备  注" prop="remark">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请填写备注（不超过200字）"
                    resize="none"
                    :disabled="isEdit && disEditOffer"
                    v-model="offerForm.remark"
                ></el-input>
            </el-form-item>

            <template v-if="isPerformanceSplit && performanceSplitList.length > 0">
                <div class="offer-item-title">
                    业绩拆分<span v-if="type == 'edit' && !canCreateOfferInfo">若要对业绩拆分比例进行更改请联系内控</span>
                </div>

                <el-form-item label="服务费" prop="serviceCharge">
                    <el-input
                        v-model="offerForm.serviceCharge"
                        placeholder="请输入服务费"
                    ></el-input>
                </el-form-item>

                <el-form-item
                    class="performance-split-form-item"
                    required
                    v-for="(perfItem, perfIndex) in performanceSplitList"
                    :key="perfIndex"
                >
                    <span slot="label" :title="perfItem.name">{{perfItem.name}}</span>
                    <template v-for="(item, index) in perfItem.splitItems">
                        <el-row :key="index">
                            <el-col :span="11" class="perfomance-item-el-col">
                                <el-form-item>
                                    <!-- ((!perfItem.canModifyDefaultUser && index < perfItem.realLength) && !isAdministrator ) ||  -->
                                    <el-select
                                        filterable
                                        :value="getExistMember(item)"
                                        placeholder="请选择用户"
                                        :disabled="(((!perfItem.canModifyDefaultUser && index < perfItem.realLength) && !isAdministrator ) || (type == 'edit' && !isAdministrator)) && !canCreateOfferInfo && ![3, 5].includes(jobType)"
                                        @change="(value) => handleSelectUser(value, perfIndex, index)"
                                    >
                                        <el-option
                                            v-for="userItem in firmMembers"
                                            :key="userItem.userId"
                                            :label="userItem.realName"
                                            :value="userItem.userId"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <template v-if="perfItem.defualtUserType && perfItem.defualtUserType === 1 && (!isEdit || [3,5].includes(recommend.jobType))">
                                <el-col :span="5" class="perfomance-item-el-col ts-input-el-col">
                                    <el-form-item>
                                        <!-- :readonly="(type == 'edit' && !isAdministrator) && !canCreateOfferInfo" -->
                                        <el-input
                                            class="perfomance-item-value-input ts-input recommendation-input"
                                            :value="item.recommendationProportion"
                                            :readonly="(((!perfItem.canModifyPercentValue && index < perfItem.realLength) && !isAdministrator) || (type == 'edit' && !isAdministrator)) && !canCreateOfferInfo && ![3, 5].includes(jobType)"
                                            placeholder="归属人分成比例"
                                            @blur="(ev) => handleValueValid(ev, perfIndex, index, true)"
                                            @input="(value) => handleValueInput(value, perfIndex, index, true)"
                                        >
                                            <template slot="append">%</template>
                                        </el-input>
                                        <span class="el-form-item__error">
                                            {{item.isRecommendationRequiredError ? '请填写归属人分成比例' :
                                            (perfItem.type > 0 && serviceChargeStatus === 1 ? '分成比例总和已超过100%' : '')}}
                                        </span>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="5" class="perfomance-item-el-col ts-input-el-col">
                                    <el-form-item>
                                        <!-- ((!perfItem.canModifyPercentValue && index < perfItem.realLength) && !isAdministrator) ||  -->
                                        <el-input
                                            class="perfomance-item-value-input ts-input"
                                            :value="item.value"
                                            :placeholder="perfItem.type > 0 ? '分成比例' : '分成金额'"
                                            :readonly="(((!perfItem.canModifyPercentValue && index < perfItem.realLength) && !isAdministrator) || (type == 'edit' && !isAdministrator)) && !canCreateOfferInfo && ![3, 5].includes(jobType)"
                                            @blur="(ev) => handleValueValid(ev, perfIndex, index)"
                                            @input="(value) => handleValueInput(value, perfIndex, index)"
                                        >
                                            <template slot="append" v-if="perfItem.type > 0">%</template>
                                        </el-input>
                                        <span class="el-form-item__error">
                                            {{item.isRequiredError && !item.isRecommendationRequiredError ? '请填写分成比例/金额' : ''}}
                                        </span>
                                    </el-form-item>
                                </el-col>
                            </template>
                            <el-col :span="10" class="perfomance-item-el-col" v-else>
                                <el-form-item>
                                    <!-- ((!perfItem.canModifyPercentValue && index < perfItem.realLength) && !isAdministrator) ||  -->
                                    <el-input
                                        class="perfomance-item-value-input"
                                        :value="item.value"
                                        :placeholder="perfItem.type > 0 ? '请输入分成比例' : '请输入分成金额'"
                                        :readonly="(((!perfItem.canModifyPercentValue && index < perfItem.realLength) && !isAdministrator) || (type == 'edit' && !isAdministrator)) && !canCreateOfferInfo && ![3, 5].includes(jobType)"
                                        @blur="(ev) => handleValueValid(ev, perfIndex, index)"
                                        @input="(value) => handleValueInput(value, perfIndex, index)"
                                    >
                                        <template slot="append" v-if="perfItem.type > 0">%</template>
                                    </el-input>
                                    <span class="el-form-item__error">
                                        {{item.isRequiredError ? '请填写分成比例/金额' :
                                        (perfItem.type > 0 && serviceChargeStatus === 1 ? '分成比例总和已超过100%' : '')}}
                                    </span>
                                </el-form-item>
                            </el-col>
                            <div
                                class="item-operation-container"
                                v-if="index == 0 && perfItem.isMulti && (type != 'edit' || isAdministrator || (type == 'edit' && canCreateOfferInfo))"
                            >
                                <span
                                    class="item-operation-btn el-icon-circle-plus-outline"
                                    @click="addPerfomanceItem(perfIndex)"
                                ></span>
                            </div>
                            <div
                                class="item-operation-container"
                                v-if="index != 0 && index > perfItem.realLength - 1 && perfItem.isMulti && (type != 'edit' || isAdministrator || (type == 'edit' && canCreateOfferInfo))"
                            >
                                <span
                                    class="item-operation-btn el-icon-remove-outline"
                                    @click="removePerfomanceItem(perfIndex, index)"
                                ></span>
                            </div>
                        </el-row>
                    </template>
                </el-form-item>
            </template>

            <p class="performance-tip">完成开票前，业绩拆分比例可能会随业务人员的变化而变化</p>
        </el-form>
        <span slot="footer" class="dialog-footer" v-if="!(disableDialog)">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button type="primary" @click="confirmHandle">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import moment from 'moment';
    import TeamService from '#/js/service/teamService.js';
    import myService from '#/js/service/myService.js';
    import provinceData from '#/js/config/provinceThreeData.json';

    export default {
        name: "offer-dialog",
        props: {
        },
        data() {
            return {
                jobType: '',
                canCreateOfferInfo: false,
                dialogVisible:false,
                loading:false,
                candidateId: '',
                btnText: '',
                status: false,
                offerForm: {
                    monthPay: '',
                    months: '',
                    commissionRate: '',
                    remark: '',
                    recruiteTime: '',
                    serviceCharge: "",
                    selectedCity: '',
                },
                offerFormRules: {
                    monthPay: [
                        {
                            trigger: 'blur',
                            validator: (rule, value, callback) => {
                                if (value === '') {
                                    callback(new Error('请输入签订的月薪'));
                                } else if (!/^\d+(.\d+)?$/g.test(value)) {
                                    callback(new Error('月薪必须为正数'));
                                } else if (Number(value) < 1 || Number(value) > 9999) {
                                    callback(new Error('月薪的范围是1~9999'));
                                } else {
                                    callback();
                                }
                            }
                        }
                    ],
                    months: [
                        {
                            trigger: 'blur',
                            validator: (rule, value, callback) => {
                                if (value === '') {
                                    callback(new Error('请输入签订的月薪数量'));
                                } else if (!/^\d+$/.test(value)) {
                                    callback(new Error('月数必须为正数!'));
                                } else if (!Number(value) > 0) {
                                    callback(new Error('月数必须大于0!'));
                                } else {
                                    callback();
                                }
                            }
                        }
                    ],
                    commissionRate: [
                        {
                            trigger: ['blur', 'change'],
                            validator: this.validatecommissionRate,
                            // validator: (rule, value, callback) => {
                            //     if (value && !/^\d+$/.test(value)) {
                            //         callback(new Error('佣金比例必须为整数'));
                            //     } else if (value && (Number(value) < 0 || Number(value) > 100)) {
                            //         callback(new Error('佣金比例必须为不超过100的非负数!'));
                            //     } else {
                            //         callback();
                            //     }
                            // }
                        }
                    ],
                    selectedCity: [
                        {
                            required: true,
                            message: '请选择工作地点',
                            trigger: 'blur'
                        },
                        {
                            trigger: ['blur', 'change'],
                            validator: this.validateSelectedCity,
                        },
                    ],
                    recruiteTime: [
                        {
                            required: true,
                            message: '请输入入职时间',
                            trigger: 'blur'
                        }
                    ],
                    serviceCharge: [
                        {
                            required: true,
                            message: '请输入服务费',
                            trigger: 'blur'
                        },
                        {
                            trigger: ['blur', 'change'],
                            validator: (rule, value, callback) => {
                                if(isNaN(+value)) {
                                    callback(new Error('服务费必须为数字格式'));
                                } 
                                if(value && (Number(value) > 9999999)) {
                                    callback(new Error('服务费不能超过9999999!'));
                                }
                                if(value && String(value).match(/\.\d*/g)?.[0]?.length>3) {
                                    callback(new Error(`最多支持两位小数!`));
                                }
                                callback();
                            }
                        }
                    ],
                    remark: [
                        {
                            max: 200,
                            message: '长度不能超过200个字',
                            trigger: 'blur'
                        }
                    ]
                },
                datePickerOption: {
                    // disabledDate(time) {
                    //     return time.getTime() < new Date(moment().format('YYYY-MM-DD').replace(/\-/ig, '\/')).getTime();
                    // }
                },
                performanceSplitList: [],
                firmMembers: [],
                isEdit: false,
                formLoading: false,
                disEditOffer: false,
                disableDialog: false,
                type: '',

                recommend: {},

                cities: provinceData.province,
            }
        },
        computed: {
            unionId() {
                return this.$store.state.user.userInfo.unionId;
            },
            isPerformanceSplit() {
                return this.$store.state.user.userInfo.isOpenPerformanceCalculation || false;
            },
            serviceChargeStatus() {
                let percent = 0,
                    result = 0;
                this.performanceSplitList.forEach(item => {
                    if(item.type > 0 && item.splitItems && item.splitItems.length > 0) {
                        for(let i = 0; i < item.splitItems.length; i ++) {
                            if(item.defualtUserType === 1) {
                                percent += (Number(item.splitItems[i].value) * Number(item.splitItems[i].recommendationProportion) / 100);
                            } else {
                                percent += Number(item.splitItems[i].value);
                            }
                        }
                    }
                });
                result = percent > 100 ? 1 : (percent < 100 ? -1 : 0);
                return result;
            },
            isAdministrator() {
                return this.$store.state.user.userInfo.isAdministrator || false;
            },
        },
        methods: {
            /**
             * 调用dialog
             * @param candidateId 候选人id
             */
            showHandle(candidateId, status, btnText, isEdit = false, disEditOffer = false, recommend, type='') {
                this.recommend = recommend || {};
                this.jobType = recommend.jobType;
                this.canCreateOfferInfo = recommend.canCreateOfferInfo;
                if(recommend && !this.isAdministrator) {
                    this.disableDialog = recommend.hasValidInvoice;
                }
                this.candidateId = candidateId;
                this.btnText = btnText;
                this.isEdit = isEdit;
                this.disEditOffer = disEditOffer;
                
                // hr和代运营编辑是可以修改上面全部内容的
                if(this.isEdit && [3, 5].includes(recommend.jobType)) {
                    this.disEditOffer = false;
                    this.canCreateOfferInfo = true;
                }

                this.type = type;
                if(status === true){
                    this.status = status;
                }

                // getEditOfferInfo
                // updateOfferInfo

                // if(this.isPerformanceSplit && !this.isEdit) {
                // // if(this.isPerformanceSplit && (!this.isEdit || (this.isEdit && this.jobType == 3 && recommend.canCreateOfferInfo))) {
                //     this.formLoading = true;
                //     if(this.firmMembers.length == 0) {
                //         this.getFirmMembers();
                //     }
                //     myService.getPerfomanceSplitInfo({
                //         recommendId: this.candidateId
                //     }).then(res => {
                //         this.formLoading = false;
                //         this.performanceSplitList = res;
                //         this.performanceSplitList.forEach((item, index) => {
                //             item.realLength = item.splitItems.length;
                //             if(item.realLength == 0) {
                //                 this.addPerfomanceItem(index);
                //             }
                //         });
                //         this.performanceSplitList.sort((a, b) => a.name.localeCompare(b.name, 'zh-CN'))
                //     }).catch(err => {
                //         console.log(err);
                //     });
                // }
                // if(this.isEdit) {
                // if(this.isEdit && !recommend.canCreateOfferInfo) {
                    this.formLoading = true;
                    // console.log(this.firmMembers)
                    if(this.isPerformanceSplit && this.firmMembers.length == 0) {
                        // this.getFirmMembers();

                        this.getFirmMembersPlus();
                    }
                    myService.getEditOfferInfo({
                        recommendId: this.candidateId
                    }).then(res => {
                        this.formLoading = false;
                        if(this.isEdit) {
                            let form = {};
                            form.commissionRate = res.commissionRate;
                            form.monthPay = res.monthPay;
                            form.months = res.months;
                            form.recruiteTime = res.recruiteTime;
                            form.remark = res.remark;
                            form.selectedCity = res.offerLocationId;
                            form.serviceCharge = res.serviceCharge && res.serviceCharge !== 0 ? res.serviceCharge : res.monthPay * res.months * res.commissionRate * 10;
                            Object.assign(this.offerForm, form);
                        }
                        
                        this.performanceSplitList = res.performanceSplits || [];
                        this.performanceSplitList.forEach((item, index) => {
                            item.realLength = item.splitItems.length;
                        });
                        this.performanceSplitList.sort((a, b) => a.name.localeCompare(b.name, 'zh-CN'))
                    }).catch(err => {
                        console.log(err);
                    });
                // }
                this.dialogVisible = true;
            },
            /**
             * 确认按钮fn
             */
            confirmHandle() {
                this.$refs.ruleForm.validate((valid) => {
                    if(valid) {
                        if(this.offerForm.serviceCharge === '0' || this.offerForm.serviceCharge === 0) {
                            this.$confirm('当前服务费填写的是“0”，确定吗？', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning',
                                closeOnClickModal: false,
                            }).then(() => {
                                this.validProportion();
                            }).catch(() => {});
                        } else {
                            this.validProportion();
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            validProportion() {
                if(this.serviceChargeStatus === 1 && this.isPerformanceSplit) {
                    shortTips("分成比例总和已超过100%，请先修改!");
                    return false;
                } else if(this.serviceChargeStatus === -1 && this.isPerformanceSplit) {
                    shortTips("分成比例总和小于100%，请先修改!");
                    return false;
                    // this.$confirm('分成比例相加不等于100%，是否继续？', '提示', {
                    //     confirmButtonText: '确定',
                    //     cancelButtonText: '取消',
                    //     type: 'warning',
                    //     closeOnClickModal: false,
                    // }).then(() => {
                    //     this.submit();
                    // }).catch(() => {});
                }
                if((this.serviceChargeStatus === 0 && this.isPerformanceSplit) || !this.isPerformanceSplit) {
                    this.submit();
                }
            },
            async submit() {
                this.loading = true;
                let recommendPosition = this.$store.state.recommendInfo.recommendPosition,
                    params = {};
                if(recommendPosition === 'candidateDetail') {
                    _tracker.track('CandidateDetailRecommendOperation', JSON.stringify({
                        recommendId: this.candidateId,
                        text: this.btnText
                    }));
                }else {
                    _tracker.track('RecommendOperation', JSON.stringify({
                        tableName: window._pageCode ? window._pageCode.replace('FloatingList', '') : '',
                        recommendId: this.candidateId,
                        position: recommendPosition,
                        text: this.btnText
                    }));
                }
                if(this.isPerformanceSplit) {
                    let performanceSplitItem = {
                            id: "",
                            name: "",
                            calculationSettingId: "",
                            userId: "",
                            type: 0,
                            value: 0
                        },
                        hasNull = false;
                    params = {
                        performanceSplitItems: []
                    };
                    this.performanceSplitList.forEach(item => {
                        for(let i = 0; i < item.splitItems.length; i++) {
                            if(!item.splitItems[i].userId || item.splitItems[i].value === "") {
                                hasNull = true;
                            } else {
                                performanceSplitItem.id = item.splitItems[i].id || "";
                                performanceSplitItem.name = item.name || "";
                                performanceSplitItem.calculationSettingId = item.calculationSettingId || "";
                                performanceSplitItem.userId = item.splitItems[i].userId || "";
                                performanceSplitItem.type = item.type;
                                if(item.defualtUserType === 1 && !this.isEdit) {
                                    performanceSplitItem.value = (Number(item.splitItems[i].value) * Number(item.splitItems[i].recommendationProportion) / 100 ) || 0;
                                } else {
                                    performanceSplitItem.value = Number(item.splitItems[i].value) || 0;
                                }
                                params.performanceSplitItems.push(JSON.parse(JSON.stringify(performanceSplitItem)));
                            }
                        }
                    });
                    if(hasNull) {
                        this.loading = false;
                        shortTips('存在未填写完整的业绩拆分项，请检查后再提交！');
                        return false;
                    }
                };
                Object.assign(params, this.offerForm);
                params.offerLocationId = this.offerForm.selectedCity;
                // if(!this.isEdit) {
                //     if (this.status) {
                //         //进入offer
                //         // myService.entry({
                //         //     RecommendationId: this.candidateId,
                //         //     Status: 8,
                //         //     OnekeyOfferModel: params,
                //         //     __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
                //         // }).then((res) => {
                //         myService.recommendStage({
                //             recommendationId: this.candidateId,
                //             unionId: this.unionId,
                //             Status: 8,
                //             onekeyOfferViewModel: params,
                //         }).then((res) => {
                //             if (res) {
                //                 this.$emit('successFn', res);
                //             }
                //             this.loading = false;
                //             this.cancelHandle();
                //         }).catch(err => {
                //             console.log(err);
                //             this.loading = false;
                //             this.$refs.ruleForm.resetFields();
                //         });
                //     } else {
                //         //一键offer
                //         params.RecommendationId = this.candidateId,
                //         params.__RequestVerificationToken = this.$store.state.verificationToken.verificationToken;
                //         myService.offer(params).then((res) => {
                //             if (res) {
                //                 this.$emit('successFn', res);
                //             }
                //             this.loading = false;
                //             this.cancelHandle();
                //         }).catch(err => {
                //             console.log(err);
                //             this.loading = false;
                //             this.$refs.ruleForm.resetFields();
                //         });
                //     }
                // } else {
                    const applyCbOfferData = {
                        serviceCharge: this.offerForm.serviceCharge,
                        performanceSplits: this.performanceSplitList,
                    };
                    params.RecommendationId = this.candidateId,
                    params.__RequestVerificationToken = this.$store.state.verificationToken.verificationToken;
                    // if(this.disEditOffer) {
                    //     // myService.updateOfferByRecommender(this.candidateId, params).then(res => {
                    //     //     this.loading = false;
                    //     //     this.$emit('successEditOffer', applyCbOfferData);
                    //     //     this.cancelHandle();
                    //     // }).catch(err => {
                    //     //     console.log(err);
                    //     //     this.loading = false;
                    //     //     this.$refs.ruleForm.resetFields();
                    //     // });
                    //     if(this.jobType == 3 && this.canCreateOfferInfo) {
                    //         let replenishParams = {
                    //             RecommendationId: params.RecommendationId,
                    //             serviceCharge: params.serviceCharge,
                    //             performanceSplitItems: params.performanceSplitItems,
                    //         }
                    //         myService.replenishOfferByRecommender(this.candidateId, replenishParams).then(res => {
                    //             this.loading = false;
                    //             // this.$emit('successEditOffer', applyCbOfferData);
                    //             if (res) {
                    //                 this.$emit('successFn', res);
                    //             }
                    //             this.cancelHandle();
                    //         }).catch(err => {
                    //             console.log(err);
                    //             this.loading = false;
                    //             this.$refs.ruleForm.resetFields();
                    //         });
                    //     } else {
                    //         myService.updateOfferByRecommender(this.candidateId, params).then(res => {
                    //             this.loading = false;
                    //             this.$emit('successEditOffer', applyCbOfferData);
                    //             this.cancelHandle();
                    //         }).catch(err => {
                    //             console.log(err);
                    //             this.loading = false;
                    //             this.$refs.ruleForm.resetFields();
                    //         });
                    //     }
                    // } else {
                        
                    // 如果状态是7,9,10,11,12要强制跳转到13
                    if(!this.isEdit) {
                        if([7, 9, 10, 11, 12].includes(this.recommend.status)) {
                            await this.updateOfferingStage(true);
                        }
                        myService
                        .recommendStage({
                            recommendationId: this.candidateId,
                            unionId: this.unionId,
                            Status: 8,
                            onekeyOfferViewModel: params,
                        })
                        .then((res) => {
                            if (res) {
                                this.$emit('successFn', res);
                            }
                            this.loading = false;
                            this.cancelHandle();
                        })
                        .catch(err => {
                            console.log(err);
                            this.loading = false;
                            this.$refs.ruleForm.resetFields();
                        });
                    } else {
                        // 编辑业绩
                        myService
                        .updateOfferInfo(this.candidateId, params)
                        .then((res) => {
                            this.loading = false;
                            if(this.disEditOffer) {
                                this.$emit('successFn', {status: 8});
                            } else {
                                this.$emit('successEditOffer', applyCbOfferData, params.recruiteTime);
                            }
                            this.cancelHandle();
                        })
                        .catch(err => {
                            console.log(err);
                            this.loading = false;
                            this.$refs.ruleForm.resetFields();
                        });
                    }
                        
                    // }
                // }
            },
            updateOfferingStage(isCompleteOffering) {
                if(this.recommend.status == 13) return Promise.resolve(true);
                const _nextStatus = this.recommend.status == 7 ? 9: this.recommend.status + 1;
                const params = {
                    isCompleteOffering: isCompleteOffering || false,
                    status: _nextStatus,
                    recommendationId: this.recommend.id,
                };
                return myService.recommendOfferingStage(params);
            },
            cancelHandle() {
                this.dialogVisible = false;
                this.$refs.ruleForm.resetFields();
            },
            addPerfomanceItem(index) {
                this.performanceSplitList[index].splitItems.push({
                    id: "",
                    userId: "",
                    value: ""
                });
            },
            getFirmMembers() {
                let param = {
                    teamId: 0,
                    take: -1,
                    isShowDimission: false,
                    _: new Date().getTime()
                };
                _request({
                    url: "/MyFirm/TeamMembers",
                    method: "GET",
                    data: param
                }).then(res => {
                    this.firmMembers = res.list;
                }).catch(err => {
                    console.log(err);
                });                
            },
            // 获取半年内离职的人员
            getFirmMembersPlus() {
                const params = {
                    take: -1,
                    firmTeamIds: ["0"],
                    startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
                    endDate: moment().format('YYYY-MM-DD')
                };
                TeamService
                    .getHistoryTeamMembersForFinancial(params)
                    .then(res => {
                        console.log(`getFirmMembersPlus:`, res);
                        this.firmMembers = res.list;
                    });
            },
            removePerfomanceItem(index, subIndex) {
                this.performanceSplitList[index].splitItems.splice(subIndex, 1);
            },
            handleValueValid(ev, index, subIndex, isRecommendationProportion = false) {
                if(!ev.srcElement.value && this.performanceSplitList[index].splitItems[subIndex].userId){
                    ev.target.parentElement.parentElement.parentElement.classList.add("is-error");
                    if(isRecommendationProportion) {
                        this.$set(this.performanceSplitList[index].splitItems[subIndex], 'isRecommendationRequiredError', true);
                    } else {
                        this.$set(this.performanceSplitList[index].splitItems[subIndex], 'isRequiredError', true);
                    }
                } else {
                    ev.target.parentElement.parentElement.parentElement.classList.remove("is-error");
                    if(isRecommendationProportion) {
                        this.$set(this.performanceSplitList[index].splitItems[subIndex], 'isRecommendationRequiredError', false);
                    } else {
                        this.$set(this.performanceSplitList[index].splitItems[subIndex], 'isRequiredError', false);
                    }
                }
            },
            calculateServiceCharge() {
                let form = this.offerForm;
                // this.isPerformanceSplit && 
                if(form.monthPay && form.months && form.commissionRate) {
                    form.serviceCharge = (form.monthPay * form.months * form.commissionRate * 10).toFixed(2);
                } else {
                    form.serviceCharge = "";
                }
            },
            handleSelectUser(value, index, subIndex) {
                if(!value) {
                    let performanceSplitFormItem = this.$el.querySelectorAll('.performance-split-form-item')[index];
                    let splitItemsNode = performanceSplitFormItem.querySelectorAll('.el-row')[subIndex];
                    splitItemsNode.querySelector('.perfomance-item-value-input').parentElement.parentElement.classList.remove("is-error");
                    this.$set(this.performanceSplitList[index].splitItems[subIndex], 'isRequiredError', false);
                } else {
                    this.$set(this.performanceSplitList[index].splitItems[subIndex], 'userId', value);
                }
            },
            handleValueInput(value, index, subIndex, isRecommendationProportion = false) {
                if(value && this.performanceSplitList[index].type > 0){
                    let itemValue = value.toString().match(/^\d+(?:\.\d{0,2})?/);
                    if(itemValue && itemValue.length > 0) {
                        if(isRecommendationProportion) {
                            this.$set(this.performanceSplitList[index].splitItems[subIndex], 'recommendationProportion', itemValue[0]);
                        } else {
                            this.$set(this.performanceSplitList[index].splitItems[subIndex], 'value', itemValue[0]);
                        }
                    } else {
                        if(isRecommendationProportion) {
                            this.$set(this.performanceSplitList[index].splitItems[subIndex], 'recommendationProportion', "");
                        } else {
                            this.$set(this.performanceSplitList[index].splitItems[subIndex], 'value', "");
                        }
                    }
                } else {
                    if(isRecommendationProportion) {
                        this.$set(this.performanceSplitList[index].splitItems[subIndex], 'recommendationProportion', value);
                    } else {
                        this.$set(this.performanceSplitList[index].splitItems[subIndex], 'value', value);
                    }
                }
            },
            getExistMember(member) {
                let userId = member.userId;
                let existMember = this.firmMembers.find(item => {
                    return item.userId == userId;
                });
                if(existMember) {
                    return userId;
                } else {
                    return member.realName;
                }
            },
            // Validate
            validatecommissionRate(rule, value, callback) {
                if(isNaN(+value)) {
                    callback(new Error(`请输入合法的比例!`));
                } else {
                    if(+value >= 100 || +value<=0) {
                        callback(new Error(`比例范围大于0，小于100!`));
                    }
                    if(value && ('' + value).match(/\.\d*/g)?.[0]?.length>3) {
                        callback(new Error(`最多支持两位小数!`));
                    }
                    callback();
                }
            },
            validateSelectedCity(rule, value, callback) {
                if(!value) {
                    callback(new Error(`请选择工作地点!`));
                } else {
                    callback();
                }
            },
            selectCity(val){
                console.log(`val---------`, val)
                // this.resumeData.province = val[0];
                // this.resumeData.city = val[1];
            },
        }
    }
</script>

<style lang="scss" scoped>
    .red {
        color: red;
    }

    .errMsg {
        padding: 10px;
        color: red;
    }
</style>
<style lang="scss">
    .offer-dialog.el-dialog {
        .el-dialog__body {
            max-height: 516px;
            overflow-y: auto;
        }
    }
    .el-form.offer-dialog-form {
        .offer-item-title {
            font-size: 16px;
            color: #333;
            line-height: 20px;
            font-weight: bold;
            margin: 20px 0;
            >span{
                font-size: 14px;
                color: #F46C6C;
                margin-left: 20px;
                font-weight: normal;
            }
            &:nth-of-type(1) {
                margin-top: 5px;
            }
        }
        .el-form-item {
            margin-bottom: 20px;
            .el-form-item__label {
                text-align: left;
                line-height: 28px;
                color: #333;
                padding-right: 7px;
            }
            .el-form-item__content {
                line-height: 28px;
                .el-input__icon {
                    line-height: 28px;
                }
                .el-input__inner {
                    height: 28px;
                    line-height: 28px;
                    border: 1px solid #ddd;
                    padding-left: 10px;
                }
                .el-date-editor {
                    .el-input__inner {
                        padding-left: 30px;
                    }
                }
                .el-cascader{
                    line-height: 28px;
                }
                .el-input-group--append .el-input__inner {
                    border-right: none;
                    padding-right: 0;
                }
                .el-input-group__append {
                    background: inherit;
                    padding-left: 10px;
                }
                .item-operation-container {
                    text-align: center;
                    height: 28px;
                    width: 22px;
                    float: left;
                    .item-operation-btn {
                        cursor: pointer;
                        color: #38bc9d;
                        font-size: 18px;
                        line-height: 28px;
                        &.el-icon-remove-outline {
                            color: #F56C6C;
                        }
                    }
                }
                .el-textarea__inner {
                    &:hover {
                        border-color: #ddd;
                    }
                    &:focus {
                        border-color: #ddd;
                    }
                }
                .el-form-item {
                    margin-bottom: 0;
                }
                .perfomance-item-el-col {
                    padding-right: 5px;
                    & + .perfomance-item-el-col {
                        padding-left: 5px;
                    }
                    &:nth-of-type(2) {
                        padding-right: 0;
                    }
                    &.ts-input-el-col {
                        padding-right: 5px;
                        &:nth-of-type(3) {
                            padding-right: 0;
                        }
                    }
                    .el-form-item {
                        position: relative;
                    }
                    .el-input-group__append {
                        padding-right: 10px;
                    }
                    .ts-input {
                        .el-input-group__append {
                            padding-left: 2px;
                            padding-right: 1px;
                        }
                        &.recommendation-input {
                            position: relative;
                            &::after {
                                content: "*";
                                position: absolute;
                                right: -10px;
                                top: 0;
                                font-size: 18px;
                                line-height: 34px;
                                color: #999;
                            }
                        }
                    }
                }
                .el-row + .el-row {
                    margin-top: 20px;
                }
            }
            .offer-monthpay-item {
                &:nth-of-type(1) {
                    padding-right: 5px;
                }
                &:nth-of-type(2) {
                    padding-left: 5px;
                }
            }
            .el-form-item__error {
                white-space: nowrap;
            }
            &:not(.is-required) {
                .el-form-item__label {
                    padding-left: 10.38px;
                }
            }
            &.performance-split-form-item {
                .el-form-item__label {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            &.is-success {
                .el-input__inner:focus {
                    border-color: #ddd !important;
                }
            }
            &.is-error {
                .el-input__inner,
                .el-input-group__append {
                    border-color: #F56C6C !important;
                }
            }
            .perfomance-item-value-input {
                .el-input__inner:read-only,
                .el-input__inner:read-only + .el-input-group__append {
                    cursor: not-allowed;
                    background-color: #F5F7FA;
                    color: #C0C4CC;
                }
            }
        }

        .performance-tip{
            color: $orange;
            font-size: 14px;
            text-align: center;
            margin: 0;
        }
    }
</style>
