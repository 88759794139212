import {floatingList} from '#/js/config/api.json';

const URL = {
    getFloatingList: '/Headhunting/FloatingList/Recommendations',
    checkCandidate: 'RecommendationFlow/Candidates/Check '
}

export default {
    /**
     *
     * @param filter 获取类型 1 - 我的推荐；2 - 收到的推荐
     * @returns {Promise<AxiosResponse<any> | never>}
     */
    getFloatingList (param = { filter, startDate, endDate} = {}) {
        return _request({
            method: 'GET',
            url: URL.getFloatingList,
            data: {
                resumeFilter: param.filter,
                startDate: param.startDate,
                endDate: param.endDate
            }
        })
    },
    /**
     * floatingList每日弹窗数据
     * @returns {*}
     */
    getTodoListStatistic(){
        return _request({
            method: 'GET',
            url: floatingList.todo_reminder,
        })
    },
    /**
     * floatingList批量接受推荐
     * @returns {*}
     */
    handleBatchAccept(ids){
        return _request({
            method: 'POST',
            url: floatingList.batch_accept,
            data:ids,
        })
    },
    handleBatchRemove(params = { ids, type }){
        return _request({
            method: 'POST',
            url: floatingList.batch_remove,
            data: params,
        })
    },
    /**
     * 检查简历是否在库中存在
     * @param recommendationId 
     * @returns {*}
     */
    checkCandidate(recommendationId = []){
        return _request({
            method: 'POST',
            url: URL.checkCandidate,
            data: {
                recommendationIds: recommendationId
            }
        })
    },
    /**
     * 获取pm的未申请发票列表
     */
    getPMUnapplyList(params) {
        return _request({
            method: 'POST',
            url: floatingList.pm_unapply_list,
            data: params,
            baseURL: "LbdOpenApi",
        })
    },
    
    /**
     * 申请开票(创建)
     */
    invoiceApply(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.pm_invoice_apply,
            data: params,
        })
    },
    /**
     * 申请开票(编辑)
     */
    invoiceApplyEdit(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.pm_invoice_apply_edit,
            data: params,
        })
    },
    /**
     * 申请开票(编辑)
     */
    invoiceApplyReapply(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.pm_invoice_apply_reapply,
            data: params,
        })
    },
    /**
     * 获取常用信息列表
     */
    getCommonInfoList() {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: floatingList.get_invoice_common_infos,
        })
    },
    /**
     * 获取常用信息列表
     */
    deleteCommonInfoList(id) {
        return _request({
            method: 'DELETE',
            baseURL: "LbdOpenApi",
            url: floatingList.delete_invoice_common_infos.replace(/\%p/, id),
        })
    },
    /**
     * 获取实体列表
     */
    getInvoiceEntity() {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: floatingList.get_invoice_entity,
        });
    },
    /**
     * 驳回
     */
    rejectApplication(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.reject_application,
            data: params,
        });
    },
    /**
     * 取消
     */
    cancelApplication(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.cancel_application,
            data: params,
        });
    },
    /**
     * 申请列表
     */
    getApplyList(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            // headers: {
            //     "Content-Type": "application/json"
            // },
            url: floatingList.get_apply_list,
            data: params
        });
    },
    
    /**
     * 开票申请表头聚合筛选
     */
     getInvoiceApplicationSearch(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.get_invoice_application_search,
            data: params
        });
    },

    /**
     * 获取候选人详情
     */
    getInvoiceItemDetail(id) {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: floatingList.get_invoice_item_detail.replace(/\%p/, id),
        });
    },

    /**
     * 获取候选人详情列表数据
     */
    getInvoiceItemDetailList(id) {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: floatingList.get_invoice_item_detail_list.replace(/\%p/, id),
        });
    },

    /**
     * 获取候选人详情分成数据
     */
    getInvoiceItemOfferSplits(id) {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: floatingList.get_invoice_item_offer_split.replace(/\%p/, id),
        });
    },


    /**
     * 添加发票附件
     */
    invoicingAttachmentsAdd(formData) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.invoicing_attachments_add,
            headers: {
                'Content-Type': 'multipart/form-data',
                // 'LoginedUserUnionId': LoginedUserUnionId,
            },
            data: formData,
            throwBusinessError: true
        });
    },
    /**
     * 删除发票附件
     */
    invoicingAttachmentsDelete(params) {
        return _request({
            method: 'DELETE',
            baseURL: "LbdOpenApi",
            url: floatingList.invoicing_attachments_delete,
            data: params,
        });
    },
    /**
     * 发票附件列表
     */
    invoicingAttachmentsList(params) {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: floatingList.invoicing_attachments_list,
            data: params,
        });
    },

    /**
     * 填写开票信息
     */
    fillInvoice(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.fill_invoice,
            data: params,
        });
    },
    
    /**
     * 填写回款信息
     */
    receiveFund(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.receive_fund,
            data: params,
        });
    },

    /**
     * 获取申请气泡数据
     */
    getBubblesData(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.get_bubble_data,
            data: params,
        });
    },
    /**
     * 导出明细
     */
    exportDetail(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            responseType: 'blob',
            throwBusinessError: true,
            url: floatingList.export_detail,
            data: params,
        });
    },
    /**
     * 导出函
     */
    noticeLetterExport(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            responseType: 'blob',
            throwBusinessError: true,
            url: floatingList.notice_letter_export,
            data: params,
        });
    },
    /**
     * 操作列表
     */
    getInvoiceOperationLogs(invoiceId) {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: floatingList.get_invoice_operation_logs.replace(/\%p/, invoiceId),
        });
    },

    /**
     * 获取Offer列表
     */
    getOfferListRecommendations(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.offer_list_recommendations,
            data: params,
        });
    },

    /**
     * 抵扣人列表
     */
     getDeductionUsers(invoiceId) {
        return _request({
            method: 'GET',
            baseURL: "LbdOpenApi",
            url: floatingList.get_deduction_users.replace(/\%p/, invoiceId),
        });
    },

    // HR直招推荐审批
    
    recommendationApplicationHRApplyList(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.recommendation_application_HR_apply_list,
            data: params,
        });
    },

    // 确认开票申请
    invoiceApplicationConfirm(params) {
        return _request({
            method: 'POST',
            baseURL: "LbdOpenApi",
            url: floatingList.invoice_application_confirm,
            data: params,
        });
    },

}
