var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "Offer",
        visible: _vm.dialogVisible,
        width: "500px",
        "before-close": _vm.cancelHandle,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "custom-class": "offer-dialog",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "ruleForm",
          staticClass: "offer-dialog-form",
          attrs: {
            model: _vm.offerForm,
            rules: _vm.offerFormRules,
            "label-width": "87px",
            disabled: _vm.disableDialog,
          },
        },
        [
          _vm.isPerformanceSplit &&
          _vm.performanceSplitList.length > 0 &&
          ![3, 5].includes(_vm.jobType)
            ? _c("div", { staticClass: "offer-item-title" }, [
                _vm._v("\n            候选人offer情况\n        "),
              ])
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "签订月薪",
                required: [3, 5].includes(_vm.jobType) ? false : true,
              },
            },
            [
              _c(
                "el-col",
                { staticClass: "offer-monthpay-item", attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "monthPay" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请输入签订的月薪",
                            disabled: _vm.isEdit && _vm.disEditOffer,
                          },
                          on: { blur: _vm.calculateServiceCharge },
                          model: {
                            value: _vm.offerForm.monthPay,
                            callback: function ($$v) {
                              _vm.$set(_vm.offerForm, "monthPay", $$v)
                            },
                            expression: "offerForm.monthPay",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("K")])],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "offer-monthpay-item", attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "months" } },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请输入年薪月份",
                            maxlength: "2",
                            disabled: _vm.isEdit && _vm.disEditOffer,
                          },
                          on: { blur: _vm.calculateServiceCharge },
                          model: {
                            value: _vm.offerForm.months,
                            callback: function ($$v) {
                              _vm.$set(_vm.offerForm, "months", $$v)
                            },
                            expression: "offerForm.months",
                          },
                        },
                        [_c("template", { slot: "append" }, [_vm._v("个月")])],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "佣金比例", prop: "commissionRate" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    placeholder: "请输入佣金比例",
                    disabled: _vm.isEdit && _vm.disEditOffer,
                  },
                  on: { blur: _vm.calculateServiceCharge },
                  model: {
                    value: _vm.offerForm.commissionRate,
                    callback: function ($$v) {
                      _vm.$set(_vm.offerForm, "commissionRate", $$v)
                    },
                    expression: "offerForm.commissionRate",
                  },
                },
                [_c("template", { slot: "append" }, [_vm._v("%")])],
                2
              ),
            ],
            1
          ),
          !_vm.isPerformanceSplit
            ? _c(
                "el-form-item",
                { attrs: { label: "服务费", prop: "serviceCharge" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入服务费" },
                    model: {
                      value: _vm.offerForm.serviceCharge,
                      callback: function ($$v) {
                        _vm.$set(_vm.offerForm, "serviceCharge", $$v)
                      },
                      expression: "offerForm.serviceCharge",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "工作地点", prop: "selectedCity" } },
            [
              _c("el-cascader", {
                attrs: {
                  props: { expandTrigger: "hover", emitPath: false },
                  options: _vm.cities,
                  "show-all-levels": false,
                  clearable: true,
                  filterable: "",
                },
                on: { change: _vm.selectCity },
                model: {
                  value: _vm.offerForm.selectedCity,
                  callback: function ($$v) {
                    _vm.$set(_vm.offerForm, "selectedCity", $$v)
                  },
                  expression: "offerForm.selectedCity",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "入职时间", prop: "recruiteTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  disabled: _vm.isEdit && _vm.disEditOffer,
                  "value-format": "yyyy-MM-dd",
                  "picker-options": _vm.datePickerOption,
                  placeholder: "选择日期",
                },
                model: {
                  value: _vm.offerForm.recruiteTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.offerForm, "recruiteTime", $$v)
                  },
                  expression: "offerForm.recruiteTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备  注", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 4 },
                  placeholder: "请填写备注（不超过200字）",
                  resize: "none",
                  disabled: _vm.isEdit && _vm.disEditOffer,
                },
                model: {
                  value: _vm.offerForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.offerForm, "remark", $$v)
                  },
                  expression: "offerForm.remark",
                },
              }),
            ],
            1
          ),
          _vm.isPerformanceSplit && _vm.performanceSplitList.length > 0
            ? [
                _c("div", { staticClass: "offer-item-title" }, [
                  _vm._v("\n                业绩拆分"),
                  _vm.type == "edit" && !_vm.canCreateOfferInfo
                    ? _c("span", [
                        _vm._v("若要对业绩拆分比例进行更改请联系内控"),
                      ])
                    : _vm._e(),
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "服务费", prop: "serviceCharge" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入服务费" },
                      model: {
                        value: _vm.offerForm.serviceCharge,
                        callback: function ($$v) {
                          _vm.$set(_vm.offerForm, "serviceCharge", $$v)
                        },
                        expression: "offerForm.serviceCharge",
                      },
                    }),
                  ],
                  1
                ),
                _vm._l(
                  _vm.performanceSplitList,
                  function (perfItem, perfIndex) {
                    return _c(
                      "el-form-item",
                      {
                        key: perfIndex,
                        staticClass: "performance-split-form-item",
                        attrs: { required: "" },
                      },
                      [
                        _c(
                          "span",
                          {
                            attrs: { slot: "label", title: perfItem.name },
                            slot: "label",
                          },
                          [_vm._v(_vm._s(perfItem.name))]
                        ),
                        _vm._l(perfItem.splitItems, function (item, index) {
                          return [
                            _c(
                              "el-row",
                              { key: index },
                              [
                                _c(
                                  "el-col",
                                  {
                                    staticClass: "perfomance-item-el-col",
                                    attrs: { span: 11 },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              filterable: "",
                                              value: _vm.getExistMember(item),
                                              placeholder: "请选择用户",
                                              disabled:
                                                ((!perfItem.canModifyDefaultUser &&
                                                  index < perfItem.realLength &&
                                                  !_vm.isAdministrator) ||
                                                  (_vm.type == "edit" &&
                                                    !_vm.isAdministrator)) &&
                                                !_vm.canCreateOfferInfo &&
                                                ![3, 5].includes(_vm.jobType),
                                            },
                                            on: {
                                              change: (value) =>
                                                _vm.handleSelectUser(
                                                  value,
                                                  perfIndex,
                                                  index
                                                ),
                                            },
                                          },
                                          _vm._l(
                                            _vm.firmMembers,
                                            function (userItem) {
                                              return _c("el-option", {
                                                key: userItem.userId,
                                                attrs: {
                                                  label: userItem.realName,
                                                  value: userItem.userId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                perfItem.defualtUserType &&
                                perfItem.defualtUserType === 1 &&
                                (!_vm.isEdit ||
                                  [3, 5].includes(_vm.recommend.jobType))
                                  ? [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass:
                                            "perfomance-item-el-col ts-input-el-col",
                                          attrs: { span: 5 },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass:
                                                    "perfomance-item-value-input ts-input recommendation-input",
                                                  attrs: {
                                                    value:
                                                      item.recommendationProportion,
                                                    readonly:
                                                      ((!perfItem.canModifyPercentValue &&
                                                        index <
                                                          perfItem.realLength &&
                                                        !_vm.isAdministrator) ||
                                                        (_vm.type == "edit" &&
                                                          !_vm.isAdministrator)) &&
                                                      !_vm.canCreateOfferInfo &&
                                                      ![3, 5].includes(
                                                        _vm.jobType
                                                      ),
                                                    placeholder:
                                                      "归属人分成比例",
                                                  },
                                                  on: {
                                                    blur: (ev) =>
                                                      _vm.handleValueValid(
                                                        ev,
                                                        perfIndex,
                                                        index,
                                                        true
                                                      ),
                                                    input: (value) =>
                                                      _vm.handleValueInput(
                                                        value,
                                                        perfIndex,
                                                        index,
                                                        true
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "template",
                                                    { slot: "append" },
                                                    [_vm._v("%")]
                                                  ),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-form-item__error",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        item.isRecommendationRequiredError
                                                          ? "请填写归属人分成比例"
                                                          : perfItem.type > 0 &&
                                                            _vm.serviceChargeStatus ===
                                                              1
                                                          ? "分成比例总和已超过100%"
                                                          : ""
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass:
                                            "perfomance-item-el-col ts-input-el-col",
                                          attrs: { span: 5 },
                                        },
                                        [
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  staticClass:
                                                    "perfomance-item-value-input ts-input",
                                                  attrs: {
                                                    value: item.value,
                                                    placeholder:
                                                      perfItem.type > 0
                                                        ? "分成比例"
                                                        : "分成金额",
                                                    readonly:
                                                      ((!perfItem.canModifyPercentValue &&
                                                        index <
                                                          perfItem.realLength &&
                                                        !_vm.isAdministrator) ||
                                                        (_vm.type == "edit" &&
                                                          !_vm.isAdministrator)) &&
                                                      !_vm.canCreateOfferInfo &&
                                                      ![3, 5].includes(
                                                        _vm.jobType
                                                      ),
                                                  },
                                                  on: {
                                                    blur: (ev) =>
                                                      _vm.handleValueValid(
                                                        ev,
                                                        perfIndex,
                                                        index
                                                      ),
                                                    input: (value) =>
                                                      _vm.handleValueInput(
                                                        value,
                                                        perfIndex,
                                                        index
                                                      ),
                                                  },
                                                },
                                                [
                                                  perfItem.type > 0
                                                    ? _c(
                                                        "template",
                                                        { slot: "append" },
                                                        [_vm._v("%")]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "el-form-item__error",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        item.isRequiredError &&
                                                          !item.isRecommendationRequiredError
                                                          ? "请填写分成比例/金额"
                                                          : ""
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _c(
                                      "el-col",
                                      {
                                        staticClass: "perfomance-item-el-col",
                                        attrs: { span: 10 },
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          [
                                            _c(
                                              "el-input",
                                              {
                                                staticClass:
                                                  "perfomance-item-value-input",
                                                attrs: {
                                                  value: item.value,
                                                  placeholder:
                                                    perfItem.type > 0
                                                      ? "请输入分成比例"
                                                      : "请输入分成金额",
                                                  readonly:
                                                    ((!perfItem.canModifyPercentValue &&
                                                      index <
                                                        perfItem.realLength &&
                                                      !_vm.isAdministrator) ||
                                                      (_vm.type == "edit" &&
                                                        !_vm.isAdministrator)) &&
                                                    !_vm.canCreateOfferInfo &&
                                                    ![3, 5].includes(
                                                      _vm.jobType
                                                    ),
                                                },
                                                on: {
                                                  blur: (ev) =>
                                                    _vm.handleValueValid(
                                                      ev,
                                                      perfIndex,
                                                      index
                                                    ),
                                                  input: (value) =>
                                                    _vm.handleValueInput(
                                                      value,
                                                      perfIndex,
                                                      index
                                                    ),
                                                },
                                              },
                                              [
                                                perfItem.type > 0
                                                  ? _c(
                                                      "template",
                                                      { slot: "append" },
                                                      [_vm._v("%")]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "el-form-item__error",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(
                                                      item.isRequiredError
                                                        ? "请填写分成比例/金额"
                                                        : perfItem.type > 0 &&
                                                          _vm.serviceChargeStatus ===
                                                            1
                                                        ? "分成比例总和已超过100%"
                                                        : ""
                                                    ) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                index == 0 &&
                                perfItem.isMulti &&
                                (_vm.type != "edit" ||
                                  _vm.isAdministrator ||
                                  (_vm.type == "edit" &&
                                    _vm.canCreateOfferInfo))
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "item-operation-container",
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "item-operation-btn el-icon-circle-plus-outline",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addPerfomanceItem(
                                                perfIndex
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                index != 0 &&
                                index > perfItem.realLength - 1 &&
                                perfItem.isMulti &&
                                (_vm.type != "edit" ||
                                  _vm.isAdministrator ||
                                  (_vm.type == "edit" &&
                                    _vm.canCreateOfferInfo))
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "item-operation-container",
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "item-operation-btn el-icon-remove-outline",
                                          on: {
                                            click: function ($event) {
                                              return _vm.removePerfomanceItem(
                                                perfIndex,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  }
                ),
              ]
            : _vm._e(),
          _c("p", { staticClass: "performance-tip" }, [
            _vm._v("完成开票前，业绩拆分比例可能会随业务人员的变化而变化"),
          ]),
        ],
        2
      ),
      !_vm.disableDialog
        ? _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelHandle } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmHandle },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }